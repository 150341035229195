import { applicationActions } from "../Data/Slices/ApplicationSlice";
import { apiDomain, quizDomian } from "../../Config";
// // const domainQA = "https://app.innovartan.com/api/";
// const domainQuiz = "https://quiz.innovartan.com/api/";
// const qaDomainQuiz = "http://quiz.edvanz.com/api/";
// // const qaDomain = "http://qa.edvanz.com/api/";
// const qaDomain = "http://qa.edvanz.com/api/";
// const quizTest = "http://quiz.edvanz.com/api/";

const apiUrl = apiDomain();
const quizUrl = quizDomian();

const fetchWithTimeout = async (url, options = {}, dispatch) => {
  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(() => reject(new Error("Request Timeout")), 50000)
  );
  const fetchPromise = fetch(url, options);
  try {
    const response = await Promise.race([fetchPromise, timeoutPromise]);
    if (!response.ok) {
      throw new Error("Failed to fetch");
    }
    return response;
  } catch (error) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getUser = async (auth, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/get-user/${auth}`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getTestList = async (userId, groupId, token, dispatch, role) => {
  try {
    console.log(apiDomain(), "apiDomain");
    if (role === "student") {
      const response = await fetchWithTimeout(
        `${quizUrl}mobile/quiz/get-student-quiz/${userId}/${groupId}/web`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            TOKEN: token,
          },
        },
        dispatch
      );
      const data = await response.json();
      return data.data;
    } else if (role === "teacher" || role === "institute") {
      const response = await fetchWithTimeout(
        `${quizUrl}mobile/teacher-quiz/get-teacher-quiz/${userId}/${groupId}/batch`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            TOKEN: token,
          },
        },
        dispatch
      );
      const data = await response.json();
      return data.data;
    }
  } catch (e) {
    console.log("data", e);
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
    return undefined;
  }
};

export const getQuizQuestion = async (token, quizID, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${quizUrl}mobile/quiz/get-test-questions/${quizID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      4000
    );
    const data = await response.json();
    console.log(data);
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};
export const getQuizDetails = async (token, quizID, userId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${quizUrl}mobile/quiz/get-quiz-details/${userId}/${quizID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    // console.log(data);
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getQuizAttempt = async (userState, quizId, dispatch) => {
  // console.log(userState, "userState getQuizAttempt");
  try {
    const reponse = await fetchWithTimeout(
      `${quizUrl}mobile/quiz/get-quiz-attempt/${userState.userId}/${quizId}/${userState.groupId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
      },
      dispatch
    );
    const data = await reponse.json();
    // console.log(data);
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const submitQuiz = async (
  userState,
  attemptId,
  answerState,
  questionMendatory,
  quizId,
  dispatch
) => {
  // console.log(
  //   userState,
  //   attemptId,
  //   answerState,
  //   questionMendatory,
  //   "submitQuiz"
  // );
  let finalyArray = [];
  if (Object.keys(questionMendatory).length !== 0) {
    const tempAnswer = answerState.filter((answer) => answer.answer !== "");
    // console.log(tempAnswer, "tempAnswer before filter");
    // console.log(questionMendatory, "questionMendatory");
    const questionCount = {
      Biology: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
      Physics: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
      Chemistry: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
      Maths: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
    };
    tempAnswer.forEach((question) => {
      const key = `${question.subject}_${question.section}`;
      // console.log(key, "key");

      if (questionMendatory[question.subject] !== undefined) {
        const maxQuestions =
          questionMendatory[question.subject][
            `section_${question.section}_mandetory`
          ];

        // Initialize questionCount for the subject and section if not present
        if (!questionCount[key]) {
          questionCount[key] = 0;
        }

        // Check if adding this question exceeds the maximum allowed questions
        if (questionCount[key] < maxQuestions) {
          finalyArray.push({
            question_id: question.questionId,
            answer: question.answer,
            attempt_time: question.attempt_time,
            question_state: question.question_state,
            position: question.position,
            attachment: question.attachment,
          });
          questionCount[key]++; // Increment the question count for the subject and section
        }
      } else {
        // console.log(
        //   "Not mandetory",
        //   question.subject,
        //   `section_${question.section}_mandetory`
        // );
        finalyArray.push({
          question_id: question.questionId,
          answer: question.answer,
          attempt_time: question.attempt_time,
          question_state: question.question_state,
          position: question.position,
          attachment: question.attachment,
        });
      }
    });

    // console.log(finalyArray, "finalyArray");
  } else {
    finalyArray = answerState;
  }

  // console.log(finalyArray, "finalyArray------");

  const tempAnswer = [];
  if (answerState.length > 0) {
    finalyArray.forEach((answer) => {
      if (answer.answer === "True") {
        tempAnswer.push({
          question_id: answer.question_id || answer.questionId,
          answer: 1,
          attempt_time: answer.attempt_time,
          question_state: answer.question_state,
          position: answer.position,
          attachment: answer.attachment,
        });
      } else if (answer.answer === "False") {
        tempAnswer.push({
          question_id: answer.question_id || answer.questionId,
          answer: 2,
          attempt_time: answer.attempt_time,
          question_state: answer.question_state,
          position: answer.position,
          attachment: answer.attachment,
        });
      } else if (typeof answer.answer === "object") {
        tempAnswer.push({
          question_id: answer.question_id || answer.questionId,
          answer: answer.answer.join(","),
          attempt_time: answer.attempt_time,
          question_state: answer.question_state,
          position: answer.position,
          attachment: answer.attachment,
        });
      } else {
        tempAnswer.push({
          question_id: answer.question_id || answer.questionId,
          answer: answer.answer,
          attempt_time: answer.attempt_time,
          question_state: answer.question_state,
          position: answer.position,
          attachment: answer.attachment,
        });
      }
    });
  }
  if (tempAnswer.length === finalyArray.length) {
    const formData = new FormData();
    formData.append("student_id", userState.userId);
    formData.append("attempt_id", attemptId);
    formData.append("quiz_id", quizId);
    formData.append("data", JSON.stringify(tempAnswer));
    console.log(tempAnswer, "tempAnswer submitQuiz");
    try {
      const response = await fetchWithTimeout(
        `${quizUrl}mobile/quiz/save-student-quiz`,
        {
          method: "POST",
          headers: {
            TOKEN: userState.token,
          },
          body: formData,
        },
        dispatch
      );
      const data = await response.json();
      console.log(data, "submiteQuiz");
      
      return data.statuscode;
    } catch (e) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Internal Server Error",
          alertType: "danger",
        })
      );
      setTimeout(() => {
        dispatch(
          applicationActions.setAlertData({
            isAlert: false,
            alertMessage: "",
            alertType: "",
          })
        );
      }, 3000);
      return 400;
    }
  }
};

export const getQuizSubmission = async (token, userId, testID) => {
  const response = await fetchWithTimeout(
    `${quizUrl}mobile/quiz/view-student-submition/${userId}/${testID}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        TOKEN: token,
      },
    }
  );

  const data = await response.json();
  return data.data;
};

export const loginEmail = async (authToken, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authToken}`,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const userLogout = async (authToken, dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}mobile/user/logout`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: authToken,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const dashboardTest = async (userId, dispatch) => {
  try {
    const response = await fetch(
      `${quizUrl}mobile/quiz/get-dashboard-test/${userId}/web`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const dashboardCourse = async (userId, requestType, token) => {
  try {
    const response = await fetch(
      `${apiUrl}mobile/dashboard/get-dashboard-shop-course`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify({
          user_id: userId,
          page_no: "1",
          request_type: requestType,
        }),
      }
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    // dispatch(
    //   applicationActions.setAlertData({
    //     isAlert: true,
    //             alertMessage:"Internal Server Error",
    //     alertType: "danger",
    //   })
    // );
    // setTimeout(() => {
    //   dispatch(
    //     applicationActions.setAlertData({
    //       isAlert: false,
    //       alertMessage: "",
    //       alertType: "",
    //     })
    //   );
    // }, 3000);
  }
};

export const dashboardVedio = async (userId, token, page_no) => {
  try {
    const response = await fetch(
      `${apiUrl}mobile/dashboard/get-dashboard-videos`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify({
          user_id: userId,
          page_no: page_no,
        }),
      }
    );

    const data = await response.json();
    console.log(data, "data");

    return data.data;
  } catch (e) {
    // dispatch(
    //   applicationActions.setAlertData({
    //     isAlert: true,
    //             alertMessage:"Internal Server Error",
    //     alertType: "danger",
    //   })
    // );
    // setTimeout(() => {
    //   dispatch(
    //     applicationActions.setAlertData({
    //       isAlert: false,
    //       alertMessage: "",
    //       alertType: "",
    //     })
    //   );
    // }, 3000);
  }
};

export const dashboardSession = async (userId, token, dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}mobile/dashboard/get-dashboard-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify({
          user_id: userId,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getUserProfile = async (token, userId, dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}mobile/user/get-profile/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const updateProfile = async (authToken, formData, dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}mobile/user/update-profile`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: authToken,
        },
        body: JSON.stringify(formData),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const changePassword = async (authToken, formData, dispatch) => {
  try {
    await fetch(
      `${apiUrl}mobile/user/change-user-password`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: authToken,
        },
        body: JSON.stringify(formData),
      },
      dispatch
    );
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getSessionJoinningUrl = async (
  token,
  userId,
  sessionId,
  groupId,
  dispatch
) => {
  // console.log(token, userId, sessionId, groupId);
  try {
    const response = await fetch(
      `${apiUrl}mobile/user/get-session-url/${groupId}/${sessionId}/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    // console.log(data);
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getBtaches = async (
  token,
  userId,
  userType,
  institute,
  dispatch
) => {
  let callingUrl = "";
  if (userType === "institute") {
    callingUrl = `${apiUrl}mobile/user/get-user-batches/${userId}/${institute}`;
  } else {
    callingUrl = `${apiUrl}mobile/user/get-user-batches/${userId}`;
  }

  try {
    const response = await fetch(
      callingUrl,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getGroupSession = async (userState, groupId, state, dispatch) => {
  // console.log(userState, "userState getQuizAttempt");
  try {
    const reponse = await fetchWithTimeout(
      `${apiUrl}mobile/user/view-session/${userState.userId}/${groupId}/${state}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
      },
      dispatch
    );
    const data = await reponse.json();
    // console.log(data);
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getchapterOfGroup = async (
  userState,
  groupId,
  subject,
  dispatch
) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/get-group-chapters`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: userState.token,
        },
        body: JSON.stringify({
          uid: userState.userId,
          group_id: groupId,
          subject: subject,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const addFeedback = async (
  userState,
  postData,
  batchState,
  dispatch
) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/save-feedback`,
      {
        method: "POST",
        headers: {
          TOKEN: userState.token,
        },
        body: JSON.stringify({
          user_id: userState.userId,
          group_id: batchState.groupId,
          data: postData,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getPayments = async (token, userId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/get-student-payments/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const updateVideoViewTime = async (videoData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/add-video-view-time`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(videoData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getVedioViewTime = async (videoData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-video-view-time`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(videoData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getMyCourses = async (
  userId,
  token,
  userType,
  institute,
  dispatch
) => {
  let callingUrl = "";
  if (userType === "institute") {
    callingUrl = `${apiUrl}mobile/shop/get-teacher-shop/${userId}/${institute}`;
  } else if (userType === "teacher") {
    callingUrl = `${apiUrl}mobile/shop/get-teacher-shop/${userId}`;
  } else {
    callingUrl = `${apiUrl}mobile/shop/get-student-shop/${userId}`;
  }

  console.log(callingUrl, "callingUrl");

  try {
    const response = await fetchWithTimeout(
      callingUrl,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getCourseDetails = async (courseId, userId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-course-details/${courseId}/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getCourseContent = async (courseName, userId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/get-course-content/${courseName}/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getRecordingTime = async (videoData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/get-recording-time`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(videoData),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const saveRecordingTime = async (videoData, token, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/session/save-recording-time`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(videoData),
      },
      dispatch
    );
    const data = await response.json();
    return data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getNotifications = async (userId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/notification-list/${userId}/1`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    dispatch(
      applicationActions.setAlertData({
        isAlert: true,
        alertMessage: "Internal Server Error",
        alertType: "danger",
      })
    );
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  }
};

export const getTestQuestions = async (token, testId) => {
  try {
    const request = await fetch(
      `${quizUrl}mobile/quiz/download-questions/${testId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
      }
    );
    const data = await request.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const addCourse = async (token, courseData, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/add-course`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(courseData),
      },
      dispatch
    );
    const responseData = await response.json();

    return responseData.data;
  } catch (e) {
    console.log(e);
  }
};

export const addCoursePrice = async (token, courseData, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/shop/add-course-price`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TOKEN: token,
        },
        body: JSON.stringify(courseData),
      },
      dispatch
    );
    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
};

export const getAllInstitute = async (dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/get-all-institute`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getInstituteBatches = async (instituteId, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/get-institute-batches/${instituteId}`,
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getGroupChapter = async (groupId, subject, dispatch) => {
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/group/get-group-chapters`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          group_id: groupId,
          subject: subject,
        }),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getFeedbackAnalysis = async (formData, dispatch) => {
  console.log(formData, "formData");
  try {
    const response = await fetchWithTimeout(
      `${apiUrl}mobile/user/feedback-analysis`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      },
      dispatch
    );
    const data = await response.json();
    return data.data;
  } catch (e) {
    console.log(e);
  }
};
