// src/components/Login.js
import React, { useEffect, useState } from 'react';
import { MdRemoveRedEye } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import './Login.css';
// import sideImg from 'images/image.png';
// import logo from 'images/logo.png';
import { useAuth } from '../../../Context/AuthContext'; // Adjust path based on your project structure
import { loginEmail } from '../../../Store/API/API';
import { useDispatch } from 'react-redux';
import { userDataActions } from '../../../Store/Data/Slices/UserDataSlice';
import Cookies from 'universal-cookie';

function Login() {

  const cookies = new Cookies()

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  const dispatch = useDispatch();


  // change favicon based on hostname
  const hostname = window.location.hostname;
  console.log(hostname, "hostname");

  const school = hostname.split('.')[0];
  // const school = 'dpsg';
  console.log(school, "school");

  const changeFavicon = (iconUrl, title) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = iconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);

    if (title) {
      document.title = title;
    }
  };

  useEffect(() => {
    if (school === 'dpsg') changeFavicon('dpsg.png', "Delhi Public School Ghaziabad");
  }, []);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const credentials = btoa(`${username}:${password}`);
    const data = await loginEmail(credentials, dispatch)
    // console.log(data, "userData");
    let tempData = {
      token: data?.token,
      userId: data?.user_id,
      instituteId: data?.institute_id,
      firstName: data?.first_name,
      lastName: data?.last_name,
      userType: data?.user_type,
    };
    // console.log(tempData);
    if (tempData.token) {
      dispatch(userDataActions.setUserData(tempData));
      // navigate('/dashboard');
      cookies.set('userData', JSON.stringify(tempData), { sameSite: 'None', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7) });
      localStorage.setItem('userData', JSON.stringify(tempData));
    }
    else {
      setError('Invalid username or password.');
    }


    // try {
    //   const response = await fetch('http://qa.edvanz.com/api/mobile/user/login', {
    //     method: 'POST',
    //     headers: {
    //       Authorization: `Basic ${credentials}`,
    //       'Content-Type': 'application/json',
    //     },
    //   });

    //   if (response.ok) {
    //     const responseData = await response.json();
    //     console.log('Login response data:', responseData);
    //     const token = responseData?.data?.token;
    //     if (token) {
    //       document.cookie = `token=${token}; Secure; HttpOnly; SameSite=Strict`;
    //       navigate('/dashboard');
    //       login(token);
    //     } else {
    //       setError('Invalid username or password.');
    //     }
    //   } else if (response.status === 401) {
    //     setError('Invalid username or password.');
    //   } else {
    //     const errorData = await response.json();
    //     setError(errorData.message || 'An error occurred. Please try again later.');
    //   }
    // } catch (error) {
    //   console.error('Error:', error);
    //   setError('An error occurred. Please try again later.');
    // }
  };

  return (
    <div className="login-container">
      <div className="container">
        <div className="section1">
          {(school === "tests" || school === "localhost" || school === 'qa') ? <img src="/images/qa.png" alt="logo" className="logo" /> :
            (
              <div className='logoContainer' >
                < img src={`${school}.jpg`} alt={`${school}-logo`} className="entry-logo" />
                < img src={"TGT.png"} alt={`${school}-logo`} className="TGT-Logo" />
              </div>
            )}
          <h2 className="welcomeText" >Log in to unlock endless learning!</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <p>Login to your account</p>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Enter Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <MdRemoveRedEye className="eye-icon" onClick={togglePasswordVisibility} />
              {/* <a href="#" className="forgot-password">
                Forgot Password?
              </a> */}
            </div>
            {error && <p className="error-message">{error}</p>}
            <div className="button-container">
              <button type="submit">Login</button>
            </div>
          </form>
        </div>
        <div className="section2">
          <img src="image.png" alt="SideImage" className="full-image" />
        </div>
      </div>
    </div>
  );
}

export default Login;
