import React from "react";
import styles from "./MCQ.module.css";

const MCQ = ({ correctOption }) => {
  const Tag = ({ tagName, tagValue, tagColor, tagButtonColor }) => {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: tagColor,
          height: "fit-content",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: tagButtonColor,
            height: "fit-content",
            borderRadius: "5px",
            padding: "0px 5px",
          }}
        >
          <p
            style={{
              marginBottom: "0px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {tagName}
          </p>
        </div>
        <p
          style={{
            marginBottom: "0px",
            fontSize: "12px",
            fontWeight: "bold",
            margin: "0px 5px",
          }}
        >
          {tagValue}
        </p>
      </div>
    );
  };

  return (
    <div>
      <div className={styles.mcqContainer}>
        <div className={styles.headerSectionContainer}>
          <div className={styles.mcqQuestion}>
            <p>
              Q1. What is the full form of HTML? What is the full form of HTML?
              What is the full form of HTML? What is the full form of HTML? What
              is the full form of HTML? What is the full form of HTML? What is
              the full form of HTML? What is the full form of HTML? What is the
              full form of HTML? What is the full form of HTML?
            </p>
          </div>
          <div className={styles.infoContainer}>
            <Tag tagName="ID" tagValue="90" tagColor="#FF933063" tagButtonColor={"#FFE4CC"} />
            <Tag tagName="Marks" tagValue="5" tagColor="#ffcccc" tagButtonColor={"#ff9191"} />
          </div>
        </div>
        <div className={styles.mcqOptions}>
          <div className={styles.mcqOptionContainer}>
            <p className={styles.mcqOptiontag}>A.</p>
            <div
              className={
                correctOption === "A"
                  ? styles.mcqOptionCorrect
                  : styles.mcqOption
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: "Hyper Text Markup Language",
                }}
              ></div>
            </div>
          </div>
          <div className={styles.mcqOptionContainer}>
            <p className={styles.mcqOptiontag}>B.</p>
            <div
              className={
                correctOption === "B"
                  ? styles.mcqOptionCorrect
                  : styles.mcqOption
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: "Hyper Text Markup Language",
                }}
              ></div>
            </div>
          </div>
          <div className={styles.mcqOptionContainer}>
            <p className={styles.mcqOptiontag}>C.</p>
            <div
              className={
                correctOption === "C"
                  ? styles.mcqOptionCorrect
                  : styles.mcqOption
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: "Hyper Text Markup Language",
                }}
              ></div>
            </div>
          </div>
          <div className={styles.mcqOptionContainer}>
            <p className={styles.mcqOptiontag}>D.</p>
            <div
              className={
                correctOption === "D"
                  ? styles.mcqOptionCorrect
                  : styles.mcqOption
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: "Hyper Text Markup Language",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MCQ;
