import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./TeacherTestView.module.css";
import MCQ from "../../../UI/OuestionTypes/MCQ/MCQ";
const TeacherTestView = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { testId } = location.state;
  // console.log(testId, "testId");

  return (
    <div className={styles.outerContainer}>
      <div className={styles.headerSection}>
        <div className={styles.headingContainer}>
          <div style={{ display: "flex" }}>
            <p className={styles.heading}>{"Test > Section Test"}</p>
            {/* <p className={styles.heading}>{testId}</p> */}
          </div>
          <div>
            <p className={styles.heading}>
              <span style={{ color: "green" }}>Published</span> || Total: 52
              marks
            </p>
          </div>
          <div className={styles.buttonContainer1}>
            <button className={styles.activeButton} disabled>
              View Test
            </button>
            <button
              className={styles.inactiveButton}
              onClick={() => {
                // console.log("View Submissions");
                navigate("/teacher-submission-view", { state: { testId } });
              }}
            >
              View Submissions
            </button>
          </div>
        </div>
        <div className={styles.buttonContainer2}>
          <button className={styles.activeButton} disabled>
            View Test
          </button>
          <button
            className={styles.inactiveButton}
            onClick={() => {
              // console.log("View Submissions");
              navigate("/teacher-submission-view");
            }}
          >
            View Submissions
          </button>
        </div>
        <div className={styles.detailcontainer}>
          <div style={{ display: "flex" }}>
            <p className={styles.heading2}>{`ID: ${testId}`}</p>
          </div>
        </div>
      </div>
      <div className={styles.contentSection}>
        <div>
          <p className={styles.questionTypeHeading}>
            Biology Section One
          </p>
          <MCQ correctOption={"D"} />
        </div>
      </div>
    </div>
  );
};
export default TeacherTestView;
