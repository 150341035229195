import React from "react";
// import classes from "./TestSearchTable.module.css";
// import TextField from "@mui/material/TextField";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const TestSearchTable = () => {
  // const [selectedDate, setSelectedDate] = useState(null);

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  return (
    <div className="rounded-lg py-1 md:p-3 w-full  border-t-2 border-t-orange-500">
      <table className="payments-table">
        <thead>
          <tr>
            <th className="text-center">Title</th>
            <th className="text-center">Publish Date</th>
            <th className="text-center">Subject</th>
            <th className="text-center">Chapter</th>
            <th className="text-center">Test Mode</th>
            <th className="text-center">Rank</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
      </table>
    </div>
  );
};
export default TestSearchTable;
